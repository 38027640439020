<!--  -->
<template>
  <div>
    <!-- 背景图 -->
    <div class="img_box">
      <img src="../../assets/news.png" class="news_img" alt />
      <div class="img_title">
        <span>{{span_title1}}</span>
        <span>{{span_title2}}</span>
      </div>
    </div>
    <!-- 内容 -->
    <div class="content" style="position: relative">
      <p class="content_title" v-show="state==2">新闻中心</p>
      <div style="display: flex;" class="menu_box">
        <div class="menu_div">
          <div
            :class="menu_div_title_id == item.id?'menu_div_title click_menu_div_title':'menu_div_title'"
            v-for="(item,index) in menu_arr"
            :key="index"
            @click="click_menu(item)"
          >{{item.titleName}}</div>
        </div>
        <div class="content_div">
          <newsList @opan_news="opan_news" :menu_div_title_id="menu_div_title_id" v-show="state==2"></newsList>
          <newsDetails v-show="state==1" :news_val="news_val"></newsDetails>
        </div>
      </div>
      <!-- <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page.sync="page.pageNum"
        :page-size="page.pageSize"
        layout="total, prev, pager, next"
        :total="page.total"
      ></el-pagination>-->
    </div>
  </div>
</template>

<script>
import newsList from './components/newsList.vue'
import newsDetails from './components/newsDetails.vue'
import { addlabel } from '../../api/api'
export default {
  data () {
    return {
      span_title1: '新闻大事 在此尽知',
      span_title2: '领先的智能化碳信息技术平台',
      menu_arr: [],
      menu_div_title_id: null,
      state: 2,//  1 打开详情  2 打开列表
      news_val: {},//新闻
      // page: {
      //   total: 100,
      //   pageSize: 10,
      //   pageNum: 1
      // }
    }
  },

  components: {
    newsList,
    newsDetails
  },
  watch: {
    /* 监听*/

  },
  mounted () {
    _hmt.push(['_trackEvent', '新闻中心', '进入']);
    this.load_menu()

  },
  methods: {
    //   handleCurrentChange (val) {
    //     this.page.pageNum = val
    //   },
    load_menu () {
      addlabel({}).then(res => {
        this.menu_div_title_id = res.data[0].id
        this.menu_arr = res.data
        // console.log(res.data)
      })
    },
    click_menu (val) {
      this.menu_div_title_id = val.id
      this.state = 2
    },
    // 打开新闻
    opan_news (val) {
      this.state = 1
      this.news_val = val
    }
  }
}

</script>
<style lang='less' scoped>
@import './style/index.less';
.click_menu_div_title {
  background-color: #056bc6 !important;
  color: #fff;
}
// .el-pagination {
//   display: inline-block;
//   position: absolute;
//   left: 50%;
//   transform: translate(-50%);
// }
</style>
