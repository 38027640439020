<!-- 新闻列表 -->
<template>
  <div>
    <div>
      <el-card
        :class="index<=2?'news_box_top':'news_box_bottom'"
        v-for="(item,index) in news_arr"
        :key="index"
        @click.native="click_card(item)"
      >
        <div v-if="index>2">
          <span>{{item.date2}}</span>
          <span>{{item.date1}}</span>
        </div>
        <div>
          <p v-if="index<=2" style="position: relative;padding-left: 25px;">
            <span class="icon_span">
              <img src="../../../assets/identification.png" style="width:100%" alt />
            </span>
            {{item.date}}
          </p>
          <p class="headLine" v-html="item.headLine"></p>
          <p v-html="item.description" class="content_p"></p>
        </div>
        <div v-if="index<=2">
          <img :src="item.coverImg" />
        </div>
      </el-card>
    </div>
    <div class="show_phone">
      <el-card v-for="(item,index) in news_arr" :key="index" v-if="index == 0" class="phone_menu">
        <div class="menu_img" v-if="index == 0" @click="click_phone(item)">
          <img :src="item.coverImg" alt />
          <span>
            1
            <span>SEP</span>
          </span>
          <span class="head_title">{{item.headLine}}</span>
        </div>
        <ul v-if="index<1">
          <li
            v-for="(item,index) in news_arr"
            :key="index"
            v-if="index<=2"
            @click="click_phone(item)"
          >
            <p>
              {{item.headLine}}
              <span>{{item.date3}}</span>
            </p>
          </li>
        </ul>
      </el-card>
      <div class="div_son">
        <el-card
          v-for="(item,index) in news_arr"
          :key="index"
          v-if="index>2"
          @click.native="click_phone(item)"
        >
          <div class="div_body">
            <p class="p_title">{{item.headLine}}</p>
            <p class="p_content" v-html="item.content"></p>
            <div style="width:40%" class="div_img">
              <img :src="item.coverImg" style="width:100%" alt />
            </div>
            <span>{{item.date}}</span>
          </div>
          <!-- <div style="height:100%">
            <div style="height:80%;width:100%">
              <img :src="item.coverImg" style="width:100%" alt />
              <span>{{item.date}}</span>
            </div>
          </div>-->
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { getByLabelId } from '../../../api/api'
export default {
  props: {
    menu_div_title_id: {
      type: Number
    }
  },
  data () {
    return {
      news_arr: [],
      screenWidth: document.body.clientWidth,
      equipment: 0,//0 pc  1 移动
    }
  },
  watch: {
    menu_div_title_id (val) {
      if (val) {
        this.load_list()
      }
    },
    screenWidth (val) {
      this.screenWidth = val;
      this.load_equipment()
    }
  },
  components: {},
  mounted () {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    }
    this.load_equipment()

  },
  methods: {
    // 判断设备
    load_equipment () {
      if (this.screenWidth < 1024) {
        this.equipment = 1
      }
    },
    load_list () {
      getByLabelId({ labelId: this.menu_div_title_id }).then(res => {
        res.data.map(item => {
          if (item.time) {
            item.date = item.time.split(' ')[0]
            item.date1 = item.date.split('-').splice(0, 2).join('-')
            item.date2 = item.date.split('-')[2]
            item.date3 = item.date.split('-').splice(1, 2).join('-')
          }
          item.pvcount++
          item.author = '零碳纪'
        })
        this.news_arr = res.data.reverse()

      })
    },
    click_card (val) {
      this.$emit('opan_news', val)
    },
    click_phone (val) {
      this.$router.push('/journalismDetails')
      localStorage.setItem('details', JSON.stringify(val))

    }
  }
}

</script>
<style lang='less' scoped>
@import '../style/newsList.less';
.icon_span {
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translate(0, -50%);
}
.content_p {
  /deep/p:first-child {
    img {
      display: none;
    }
  }
}
</style>
